import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "markdown-and-mdx-for-content"
    }}>{`Markdown and MDX for content`}</h1>
    <p>{`As you worked on new pages in the previous exercies, you may have noticed they were kind of cumbersome. Lots of typing.`}</p>
    <p>{`To create a `}<a parentName="p" {...{
        "href": "https://baconipsum.com"
      }}>{`Bacon Ipsum`}</a>{` page with a few paragraphs, subheadings, text styles, and an image takes lots of markup.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Container>
  <Heading fontSize={6}>Bacon</Heading>
  <p>
    Spicy jalapeno bacon ipsum dolor amet <b>turducken porchetta</b> tail, ...
  </p>
  <Heading fontSize={4}>Ipsum</Heading>
  <p>
    Hamburger pork belly tenderloin buffalo tail <em>jerky corned beef</em>. ...
  </p>
  <Image src="https://i.imgur.com/kEj1ePK.png" />
</Container>
`}</code></pre>
    <p>{`Using markdown, that same content looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`# Bacon

Spicy jalapeno bacon ipsum dolor amet **turducken porchetta** tail, ...

## Ipsum

Hamburger pork belly tenderloin buffalo tail _jerky corned beef_. ...

![](https://i.imgur.com/kEj1ePK.png)
`}</code></pre>
    <p>{`Which would you rather write? 😉`}</p>
    <h2 {...{
      "id": "gatsby-mdx"
    }}>{`Gatsby MDX`}</h2>
    <p>{`As often happens with Gatsby, there's a plugin for this. Add the MDX source plugin, configure a few options and voila: you can create new pages by adding `}<inlineCode parentName="p">{`*.mdx`}</inlineCode>{` files.`}</p>
    <p>{`The benefit of MDX over Markdown is that it supports all the usual Markdown syntax `}<em parentName="p">{`and`}</em>{` lets you mix with React components.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Continue with your previous solution or jump to my `}<inlineCode parentName="p">{`exercise-3/gatsby`}</inlineCode>{` project.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`gatsby-plugin-mdx`}</inlineCode>{`, `}<inlineCode parentName="p">{`@mdx-js/mdx`}</inlineCode>{`, and `}<inlineCode parentName="p">{`@mdx-js/react`}</inlineCode>{`. Enable the plugin in your `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// gatsby-config.js
{
    resolve: 'gatsby-plugin-mdx',
    options: {
        extensions: ['.mdx', '.md']
    }
}
`}</code></pre>
    <p>{`Then create an `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` page in `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` and check it out. Try importing `}<inlineCode parentName="p">{`<Container>`}</inlineCode>{` from ThemeUI and wrapping the whole markdown page.`}</p>
    <p>{`Markdown becomes a first-class citizen in React and vice-versa 🤘`}</p>
    <h4 {...{
      "id": "sub-exercise-0"
    }}>{`Sub exercise 0`}</h4>
    <p>{`Add a page title with the SEO component.`}</p>
    <h4 {...{
      "id": "sub-exercise-1"
    }}>{`Sub exercise 1`}</h4>
    <p>{`Try installing the `}<inlineCode parentName="p">{`gatsby-remark-images`}</inlineCode>{` plugin and enable it in MDX options`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// gatsby-config.js
options: {
    gatsbyRemarkPlugins: [{
        resolve: "gatsby-remark-images",
        options: {
            markdownCaptions: false,
            maxWidth: 890,
            linkImagestoOriginal: false,
            showCaptions: ["title", "alt"],
            withWebp: true,
            wrapperStyle: "text-align: center; font-style: italic",
            tracedSVG: {
            color: \`lightgray\`,
            optTolerance: 0.4,
            turdSize: 100,
            turnPolicy: "TURNPOLICY_MAJORITY",
            },
            loading: "lazy",
        }
    }],
    plugins: [{ resolve: "gatsby-remark-images" }]
}
`}</code></pre>
    <p>{`Link to an image in `}<inlineCode parentName="p">{`src/images`}</inlineCode>{`. You'll see a cool effect.`}</p>
    <h4 {...{
      "id": "sub-exercise-2"
    }}>{`Sub exercise 2`}</h4>
    <p>{`To prove that Markdown is now a full member of your toolkit, try this:`}</p>
    <ol>
      <li parentName="ol">{`Create an mdx file in `}<inlineCode parentName="li">{`src/components`}</inlineCode></li>
      <li parentName="ol">{`Import the file on your MDX page`}</li>
      <li parentName="ol">{`Render it as a React component`}</li>
      <li parentName="ol">{`Do the same in `}<inlineCode parentName="li">{`index.js`}</inlineCode></li>
    </ol>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-3-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "nextjs-mdx"
    }}>{`NextJS MDX`}</h2>
    <p>{`Much like Gatsby, there's an official MDX plugin for NextJS. Gives you all of the same options, configuration is a little different.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Continue with your previous solution or jump to my `}<inlineCode parentName="p">{`exercise-3/nextjs`}</inlineCode>{` project.`}</p>
    <p>{`Start by installing the `}<inlineCode parentName="p">{`@next/mdx`}</inlineCode>{` and `}<inlineCode parentName="p">{`@mdx-js/loader`}</inlineCode>{`. Then create a `}<inlineCode parentName="p">{`next.config.js`}</inlineCode>{` file in project root.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// next.config.js
const withMDX = require("@next/mdx")({
  extension: /\\.mdx?$/,
})
module.exports = withMDX({
  pageExtensions: ["js", "jsx", "mdx"],
})
`}</code></pre>
    <p>{`Then create an `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` page in `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` and check it out. Try importing `}<inlineCode parentName="p">{`<Container>`}</inlineCode>{` from ThemeUI and wrapping the whole markdown page.`}</p>
    <p>{`Markdown becomes a first-class citizen in React and vice-versa 🤘`}</p>
    <h4 {...{
      "id": "sub-exercise-0-1"
    }}>{`Sub exercise 0`}</h4>
    <p>{`NextJS won't let you render a `}<inlineCode parentName="p">{`<Head>`}</inlineCode>{` component in MDX like you could with Gatsby. Instead you'll have to:`}</p>
    <ol>
      <li parentName="ol">{`make a `}<inlineCode parentName="li">{`Layout`}</inlineCode>{` component in `}<inlineCode parentName="li">{`components/layout`}</inlineCode></li>
      <li parentName="ol">{`have it render children and a Head`}</li>
      <li parentName="ol">{`👇`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// top MDX file
import { Layout } from "../components/Layout"
export default ({ children }) => <Layout title="Bacon Ipsum">{children}</Layout>
`}</code></pre>
    <h4 {...{
      "id": "sub-exercise-1-1"
    }}>{`Sub exercise 1`}</h4>
    <p>{`You can embed images by adding them to the `}<inlineCode parentName="p">{`public/images`}</inlineCode>{` directory and linking with `}<inlineCode parentName="p">{`![](/images/...)`}</inlineCode></p>
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-3-solution`}</inlineCode>{` branch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      